/** Page: About */
img.avatar {
    max-height: 250px;
    max-width: 250px;
    box-shadow: inset 0 -1em 1em rgba(#000, 0.1), 0 0 0 0.05em #FFF, 0.1em 0.1em 0.333em rgba(#000, 0.3);
    filter: grayscale(1) contrast(110%);
    border-radius: 4rem !important;
    float: right;

    &:hover {
        filter: none;
    }
}

.footnotes {
    font-size: smaller;
    display: inline-block;
    background-color: #f8f9fa;
}