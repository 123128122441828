/** Footer */
$footer-height: 1.5em;

@mixin shy {
    opacity: 0.2;

    &:hover {
        opacity: inherit;
    }
}

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: $footer-height;
}

#install {
    @include shy;

    padding: 0px 10px;
    margin: 0;
}

nav.bg-light {
    padding-top: 0;
    padding-bottom: 0;
}

nav ol.breadcrumb {
    border-radius: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
}

.navbar-toggler {
    padding: 2px 4px;
    font-size: inherit;
}

a {
    color: black;

    &:hover {
        color: black;
    }
}

a:not([href^='#']):not([href^='/']) {
    text-decoration-line: underline;
    text-decoration-color: lightseagreen;
    &:after {
        font-family: "Material Symbols Outlined";
        font-weight: 900;
        content: "\e879";
        font-size: xx-small;
        vertical-align: super;
        padding-left: 0.2em;
        color: lightseagreen;
    }

    &:hover {
        color: lightseagreen;
        text-decoration-color: lightseagreen;
    }
}

blockquote {
    background-color: #f8f9fa;

    &::before {
        content: open-quote;
        font-weight: 900;
        font-size: 50px;
        float: left;
    }
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $footer-height;
    line-height: $footer-height;

    .badge {
        @include shy;
    }
}

.qr {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: $footer-height;
}

