/** Plugins - Tags */
.date {
    min-width: 7em;
    display: inline-block;
}

time.moment,
span.tag {
    white-space: nowrap;
}

.emoji {
    filter: grayscale(1);

    &:after {
        content: " ";
    }

    &:hover {
        filter: none;
    }
}